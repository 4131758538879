






























import {
  Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { emailValidator } from '@/services/defaultFormValidators';
import { User } from '@/models/entities/User';
import { ApiFacade } from '@/services/ApiFacade';
import { VForm } from '@/types/VForm';

@Component({})
export default class FindUser extends Vue {
  @Inject() apiFacade!: ApiFacade;
  @Prop({ type: Boolean, default: false }) hideWeFindAndAdd!: boolean;

  rules = {
    emailValidator,
  };

  email: string = '';
  error: Error | string | null = null;
  user: User | null = null;
  isUserFinding: boolean = false;

  async findUserByEmail(event: Event | null = null) {
    if (!(this.$refs.form as VForm).validate()) {
      return;
    }

    this.error = null;
    this.user = null;

    if (event) {
      event.preventDefault();
    }

    if (!this.email) {
      return;
    }

    let user;
    this.isUserFinding = true;
    try {
      user = await this.apiFacade.findUserByEmail(this.email);
    } finally {
      this.isUserFinding = false;
    }

    if (!user) {
      user = User.createFromDto({ email: this.email });
    }

    this.user = user;
    this.$emit('result', user);
  }
}
