






















































import {
  Component,
  Model,
  Vue, Watch,
} from 'vue-property-decorator';
import { User } from '@/models/entities/User';
import {
  phoneNumberOrEmptyValidator,
  requiredValidator,
} from '@/services/defaultFormValidators';
import { VForm } from '@/types/VForm';

interface NewUserForm {
  $refs: {
    form: VForm;
  };
}

@Component({})
class NewUserForm extends Vue {
  @Model() user!: User;

  isValid: boolean = false;

  rules = {
    requiredValidator,
    phoneNumberOrEmptyValidator,
  };

  @Watch('isValid', {
    immediate: true,
  })
  onValidationStateChange(val: boolean) {
    this.$emit('validation', Boolean(val));
  }

  public validate() {
    return this.$refs.form.validate();
  }
}

export default NewUserForm;
