




























































































































































import Vue from 'vue';
import {
  Component,
  Inject,
  InjectReactive,
  Watch,
} from 'vue-property-decorator';
import Page from '@/components/Page.vue';
import { Course } from '@/models/entities/Course';
import FindUser from '@/views/users/components/FindUser.vue';
import NewUserForm from '@/views/users/components/NewUserForm.vue';
import { User } from '@/models/entities/User';
import { AppStore } from '@/store/types/AppStore';
import Subtitle from '@/components/Subtitle.vue';
import LoadingContent from '@/components/LoadingContent.vue';
import { ApiFacade } from '@/services/ApiFacade';
import UserAvatar from '@/views/profile/components/UserAvatar.vue';
import TeachersList from '@/views/courses/components/TeachersList.vue';
import { CellLease } from '@/models/entities/CellLease';
import { Cell } from '@/models/entities/Cell';
import { Polygon } from '@/models/entities/Polygon';

/**
 * userId => cellId
 */
type StudentsCellMap = Record<number, number>;

@Component({
  components: {
    TeachersList,
    UserAvatar,
    LoadingContent,
    Subtitle,
    FindUser,
    Page,
    NewUserForm,
  },
})
export default class CourseScheduleView extends Vue {
  @InjectReactive() course!: Course;
  @InjectReactive() isLoading!: boolean;
  isLocalLoading: boolean = false;
  @Inject() appStore!: AppStore;
  @Inject() apiFacade!: ApiFacade;
  @Inject() isCourseTeacher!: () => boolean;
  @Inject() isCourseAdmin!: () => boolean;

  studentsCellMap: StudentsCellMap = {};
  studentsStatus: Record<number, 'loading' | 'ok' | 'error' | undefined> = {};
  polygon: Polygon | null = null;

  mounted() {
    this.refreshAllData();
  }

  get isSuperAdmin() {
    return this.appStore.getters.profile?.isSuperAdmin;
  }

  get isManagingUsersAllowed(): boolean {
    return Boolean(this.appStore.getters.profile?.isSuperAdmin)
      || this.isCourseAdmin()
      || this.isCourseTeacher();
  }

  get teachers(): User[] {
    return this.course.teachers;
  }

  get admins(): User[] {
    return this.course.admins;
  }

  get students(): User[] {
    return this.course.students;
  }

  get studentsMappedById(): { [studentId: number]: User } {
    return this.students.reduce((res, student) => {
      res[student.userId] = student;
      return res;
    }, {} as this['studentsMappedById']) || {};
  }

  get cellsMappedById(): { [cellId: number]: Cell } {
    return this.polygon?.cells.reduce((res, cell) => {
      res[cell.cellId] = cell;
      return res;
    }, {} as this['cellsMappedById']) || {};
  }

  get cells(): { name: string; cellId: number }[] {
    if (!this.course || !this.polygon) {
      return [];
    }

    const items = this.polygon.cells
      .filter((c): c is (Cell & { sequentialNumber: number }) => !!c.sequentialNumber)
      .map((c) => {
        return {
          name: `Ячейка: ${c.sequentialNumber}`,
          cellId: c.cellId,
        };
      });
    items.unshift({
      cellId: 0,
      name: 'Нет доступа',
    });
    return items;
  }

  handleUpdateUser() {
    this.$emit('update-course');
  }

  getCellNameByUserId(userId: number): string {
    const cellId = this.studentsCellMap[userId];
    return this.cellsMappedById[cellId]?.displayName || 'Нет';
  }

  async updateLeases() {
    const leases = await this.apiFacade.fetchCellLeasesForPolygon(this.course.polygon);
    await this.createStudentsMap(leases);
  }

  async createStudentsMap(leases: CellLease[]) {
    if (!this.course) {
      return;
    }

    this.studentsCellMap = {};

    const leasesByUserId = leases.reduce((res, cl) => {
      res[cl.user.userId] = cl.cell.cellId;
      return res;
    }, {} as Record<number, number>);

    // eslint-disable-next-line no-unused-expressions
    this.students.forEach((student) => {
      this.$set(this.studentsCellMap, student.userId, leasesByUserId[student.userId] || 0);
    });
  }

  async handleStudentCellChange(userId: number, cellId: number) {
    // this.setUserStatus(userId, 'error');
    // this.setUserStatus(userId, 'ok');
    try {
      this.setUserStatus(userId, 'loading');
      await this.apiFacade.unlinkAllCellsFromUser(this.studentsMappedById[userId]);
      if (cellId) {
        await this.apiFacade.linkUserAndCell(this.studentsMappedById[userId], this.cellsMappedById[cellId]);
      }
      this.setUserStatus(userId, 'ok');
      setTimeout(() => {
        this.setUserStatus(userId, undefined);
      }, 3000);
    } catch (err) {
      this.setUserStatus(userId, 'error');
    }
  }

  setUserStatus(userId: number, status: (this['studentsStatus'][0])) {
    this.$set(this.studentsStatus, userId, status);
  }

  @Watch('course')
  async refreshAllData(touchLoading: boolean = true) {
    if (!this.course) {
      return;
    }
    if (touchLoading) {
      this.isLocalLoading = true;
    }
    try {
      this.polygon = await this.apiFacade.fetchPolygonInfo(this.course.polygon.polygonId);
      const leases = await this.apiFacade.fetchCellLeasesForPolygon(this.polygon);
      await this.createStudentsMap(leases);
    } catch (err) {
      console.error(err);
    } finally {
      if (touchLoading) {
        this.isLocalLoading = false;
      }
    }
  }

  async handleClear() {
    try {
      this.isLocalLoading = true;
      const promises = this.students.map((u) => {
        return this.apiFacade.unlinkAllCellsFromUser(u);
      });
      await Promise.allSettled(promises);
      await this.refreshAllData(false);
    } catch (err) {
      console.error(err);
    } finally {
      this.isLocalLoading = false;
    }
  }

  handleRefresh() {
    this.$emit('update-course');
  }
}
